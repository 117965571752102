<template>
    <div class="map-page-content map-center-layout" v-if="!loading">
        <lessons mode="mini">
            <lesson
                v-for="(lesson, key) in lesson.lessons"
                :class="{'map-current': lesson.id == $route.params.id}"
                :key="key"
                :title="lesson.title"
                :excerpt="lesson.summary"
                :progress="lesson.progress"
                :link="'/e-learning/lesson/' + lesson.id"
                >
            </lesson>
        </lessons>
        <div class="map-pdf-video">
            <pdf-viewer
                v-if="pdf && pdf.url"
                :src="pdf.url"
                />
            <video-player
                v-if="video && video.url"
                :src="video.url"
                :title="video.title"
                :date="video.created_at"
                :summary="video.summary"
            />
        </div>
        <div class="map-lesson-content">
            <comments
                label="RESPONSES"
                :comments="lesson.comments"
                :post_type="PostType.LESSON"
                :post_id="$route.params.id"
                >
            </comments>
            <articles class="map-lesson-resources" :label="(resources.length ? 'Resources' : undefined)">
                <div v-if="!resources.length" class="map-resources-empty">
                    <img src="@/assets/images/stars.svg" />
                    <h2>For now, there are no resources here.</h2>
                </div>
                <post
                    v-for="(resource, key) in resources"
                    :key="key"
                    :featured="key === 0"
                    :image="resource.cover_image"
                    :title="resource.title"
                    :date="resource.created_at"
                    :excerpt="resource.summary"
                    :link="'/resource/' + resource.id"
                    >
                </post>
            </articles>
            <questions-quiz v-if="questions" :questions="questions"></questions-quiz>
            <todo-list :items="todo"></todo-list>
            <div class="">
                <h1 class="map-page-title">Discover the Latest News</h1>
                <articles :style="'news'" :loading="loading">
                    <p v-if="!posts || !posts.length">There are no lates news</p>
                    <news-post
                        v-for="(news, key) in posts"
                        :key="key"
                        :featured="news.featured"
                        :image="news.cover_image"
                        :title="news.title"
                        :author="news.author"
                        :date="news.created_at"
                        :excerpt="news.summary"
                        :link="'/news/' + news.id"
                        >
                    </news-post>
                </articles>
            </div>
        </div>
    </div>
</template>

<script>
    import Comments from '@/components/Comments';
    import PdfViewer from '@/components/PdfViewer';
    import VideoPlayer from '@/components/VideoPlayer';
    import Lessons from '@/components/Lessons/Lessons';
    import Lesson from '@/components/Lessons/Lesson';
    import QuestionsQuiz from '@/components/Lessons/Blocks/QuestionsQuiz';
    import TodoList from '@/components/Lessons/Blocks/TodoList';
    import Articles from '@/components/Articles/Articles';
    import Post from '@/components/Articles/Post';
    import NewsPost from '@/components/Articles/NewsPost';

    import { PostService, PostType } from '@/services';

    export default
    {
        components:
        {
            Comments,
            VideoPlayer,
            PdfViewer,
            Lessons,
            Lesson,
            Articles,
            Post,
            NewsPost,
            QuestionsQuiz,
            TodoList,
        },
        props:
        {
            lesson: Object,
        },
        setup()
        {
            return {
                PostType: PostType,
            };
        },
        data()
        {
            return {
                loading: true,
                resources: [],
                posts: [],
                pdf: undefined,
                video: undefined,
                questions: undefined,
                todo: undefined,
                progress: 0,
                is_done: false,
            };
        },
        async mounted()
        {
            this.loading = true;

            await this.get_resources();
            await this.get_posts();

            this.parse_content(this.lesson.content);

            this.loading = false;

            document.querySelector('#app .main-layout > .ps').onscroll = (ev) =>
            {
                const target_el = ev.target;

                const scroll_progress = (target_el.scrollTop + window.innerHeight) / target_el.scrollHeight * 100;

                this.progress = Math.ceil(Math.max(this.progress, Math.min(100, scroll_progress)));

                if (this.progress === 100 && !this.is_done)
                {
                    PostService.done(this.lesson.id);

                    this.is_done = true;

                    // this.lesson.is_done = true;
                }
            };
        },
        methods:
        {
            async get_resources()
            {
                if (this.lesson && this.lesson.resources && this.lesson.resources.length)
                {
                    const response = (await PostService.all(PostType.RESOURCE, {
                        ids: this.lesson.resources,
                        exclude: 'content',
                        'order-by': 'created_at',
                    })).data;

                    this.resources = response.data;
                }
            },

            async get_posts()
            {
                const response = (await PostService.all(PostType.NEWS, {
                    page: this.page,
                    // country: this.country,
                    // topic: this.topic,
                    // tag: this.tag,
                    exclude: 'content',
                    'per-page': this.per_page,
                    'order-by': 'featured,created_at',
                })).data;
                const posts = response.data;

                this.posts = posts;
            },

            get_author_picture()
            {
                if (this.author && this.author.image)
                {
                    return process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.author.image.id;
                }
            },

            parse_content(content)
            {
                const temp_el = document.createElement('div');

                temp_el.innerHTML = content;

                this.pdf = JSON.parse(temp_el.querySelector('.pdf-block').innerText.trim()) || undefined;
                this.video = JSON.parse(temp_el.querySelector('.video-block').innerText.trim()) || undefined;
                this.questions = JSON.parse(temp_el.querySelector('.check-quiz-block').innerText.trim()) || [];
                this.todo = JSON.parse(temp_el.querySelector('.todo-block').innerText.trim()) || [];
            },
        },
    };
</script>
